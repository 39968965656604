// AVA Macro DSL
// the << text here >> is a 'phraseSelector'
//
// For lab lookup the syntax is:
// <<labs:hba1c>>
// For medication lookup the syntax is:
// <<medications:all>>
// For vitals lookup the syntax is:
// <<vitals:bp>>
import $ from 'jquery';
import { 
  focusFirstMacroMultiSelect,
  openMultiSelectWindow,
  moveMultiSelectFocus,
  selectFocussedMacro,
  getNewPhrase,
  removeMacroMultiSelect,
  getCursorXY
} from './macro-dom-helpers';
import { 
  setConjunctionCharacter,
  setConjunctionWord,
  setRemoteUrl 
} from './macro-text-helpers';


export function initPlainTextDynamicMacros() {

  window.addEventListener('DOMContentLoaded', (function(e) {
    $('body').on('keydown', '.macro-textarea', event => {
      const actionKeys = ['Tab', 'Enter', 'ArrowDown', 'ArrowUp', ' ']
      if (!actionKeys.includes(event.key)) { 
        removeMacroMultiSelect()
        return event
        }
      // Tab Key
      if (event.key === 'Tab') {
        // Remove Menu if it is present
        removeMacroMultiSelect()
        
        let phrase = highlightPhraseSelector(event)
        if (phrase) {
          event.preventDefault()
          handleDynamicMacro(event)
        }
      }
    });
    
    $('body').on('keydown', '.macro-textarea', event => {
      // Enter Key for Selected Text
      if (event.key === 'Enter') {
        if (macroMultiSelectPresent()) {
          event.preventDefault()
          commitMacroChanges(event.currentTarget)
          return false
        } else if (macroMultiSelectInfoPresent()) {
          acceptSelectedText(event)
          event.preventDefault()
          return false
        } else if (selectedTextIsDynamicMacro(event)) {
          acceptSelectedText(event)
          event.preventDefault();
          return false
        }
      }
    });
    
    $('body').on('keydown', '.macro-textarea', event => {
      // Down Key for Options
      if (event.key === 'ArrowDown') {
        // If box is open prevent 'unselecting' with the arrow keys
        if (macroMultiSelectPresent()) {
          event.preventDefault()
          moveMultiSelectFocus(1)
          return false
        }
      }
    });
    
    $('body').on('keydown', '.macro-textarea', event => {
      // Up Key Options
      if (event.key === 'ArrowUp') {
        // If box is open prevent 'unselecting' with the arrow keys
        if (macroMultiSelectPresent()) {
          event.preventDefault()
          moveMultiSelectFocus(-1)
          return false
        }
      }
    });
    
    $('body').on('keydown', '.macro-textarea', event => {
      // Space Key Options
      if (event.key === ' ') {
        // If box is open prevent 'unselecting' with the arrow keys
        if (macroMultiSelectPresent()) {
          event.preventDefault()
          selectFocussedMacro()
          return false
        }
      }
    });
  }));
}


// Bits
const dynamicMacroPhraseSelectorRegex = /<<.+?>>/
const dynamicMacroPhraseSelectorRegexGlobal = /<<.+?>>/g

const getSubstring = (el, shift) => {
	const inputText = $(el).val()
	let outputText = ''

	if (shift === true) {
		outputText = inputText.substring(0, el.selectionStart)
	} else {
		outputText = inputText.substring(el.selectionEnd)
	}
	return outputText
}

const getSubstringIndex = (string, phraseMatches, shift) => {
	// Return an array of index [start, end]
	let selection = ''
	let start = 0
	let end = 0
	// Return false early if no matches
	if (!phraseMatches) {
		return [0, 0]
	}
	// Get correct selection
	if (shift) {
		selection = phraseMatches[phraseMatches.length - 1]
		start = string.lastIndexOf(selection)
		end = start + selection.length
	} else {
		selection = phraseMatches[0]
		start = string.indexOf(selection)
		end = start + selection.length
	}
	return [start, end]
}

const highlightPhraseSelector = (event) => {
	// Set Const
	const el = event.currentTarget
	const shift = event.shiftKey
	// Get Text
	const potentialText = getSubstring(el, shift)
	const phraseMatches = potentialText.match(dynamicMacroPhraseSelectorRegexGlobal)
	// Return unless matches
	if (!phraseMatches) {
		return false
	}
	// Get Text
	const substringIndex = shift ? 0 : el.selectionEnd
	const indexArray = getSubstringIndex(potentialText, phraseMatches, shift)
	const finalIndex = [indexArray[0] + substringIndex, indexArray[1] + substringIndex]
	// Select Text
	el.setSelectionRange(finalIndex[0], finalIndex[1])
	return true
}

const selectNext = (event) => {
	const el = event.currentTarget
	const potentialText = getSubstring(el, false)
	const phraseMatches = potentialText.match(dynamicMacroPhraseSelectorRegexGlobal)
	// Return unless matches
	if (!phraseMatches) {
		return false
	}
	// Get Text
	const substringIndex = el.selectionEnd
	const indexArray = getSubstringIndex(potentialText, phraseMatches, false)
	const finalIndex = [indexArray[0] + substringIndex, indexArray[1] + substringIndex]
	el.setSelectionRange(finalIndex[0], finalIndex[1])
  handleDynamicMacro(event)
	return true
}

const acceptSelectedText = (event) => {
	const el = event.currentTarget
	const length = (el.selectionEnd - el.selectionStart)
	const textAreaText = $(el).val()
	// If no selection return false
	if (length === 0) {
		return false
	}
	const selectionText = textAreaText.substring(el.selectionStart, el.selectionEnd)
	const cleanSelection = selectionText.replace('<<', '').replace('>>', '')
	const cleanText = replaceFromStringIndex(textAreaText, el.selectionStart, selectionText, cleanSelection)
	const finalCaretPosition = el.selectionEnd - 4
	$(el).val(cleanText)
	el.setSelectionRange(finalCaretPosition, finalCaretPosition)
	removeMacroMultiSelect()
	selectNext(event)
	return true
}

function replaceFromStringIndex(originalString, index, searchValue, replaceValue) {
  // Check if index is within the string bounds
  if (index < 0 || index > originalString.length) {
      return originalString;  // Return unchanged string if index is out of bounds
  }

  // Split the string at the specified index
  const beforeIndex = originalString.substring(0, index);
  const afterIndex = originalString.substring(index);

  // Perform the replacement on the substring after the index
  const modifiedAfterIndex = afterIndex.replace(searchValue, replaceValue);

  // Concatenate the unmodified and modified parts of the string
  return beforeIndex + modifiedAfterIndex;
}

const getSelectionTextFromElement = (el) => {
  const textAreaText = $(el).val()
	return textAreaText.substring(el.selectionStart, el.selectionEnd)
}

const launchMultiSelect = (event) => {
	const el = event.currentTarget
  const selectionText = getSelectionTextFromElement(el)
	const conjunctionCharacter = setConjunctionCharacter(selectionText)
	const remoteUrl = setRemoteUrl(selectionText)
	const conjunctionWord = setConjunctionWord(conjunctionCharacter)

  if (!isSelectionTextValid(selectionText)) { return false };

  const position = getCursorXY(el, el.selectionEnd)
  const top  = position.y + (el.selectionEnd * 0.3)
  const left = position.x + 15
  const options = getOptionsFromDynamicMacro(selectionText, conjunctionCharacter);
  openMultiSelectWindow(options, conjunctionWord, remoteUrl, top, left)
  focusFirstMacroMultiSelect()
  return true
}

export const getOptionsFromDynamicMacro = (dynamicMacroText, conjunctionCharacter) => 
  dynamicMacroText.replace('<<', '').replace('>>', '').split(conjunctionCharacter).filter((a) => a);

export const isSelectionTextValid = (text) => {
  // Do not launch if nothing is selected or selection is not between phraseSelectors
  if (text.length < 1) { return false }

  return true
}

const macroMultiSelectPresent = () => {
	return !!document.getElementById('macro-multi-select')
}

const macroMultiSelectInfoPresent = () => {
	return !!document.getElementById('macro-multi-select-info')
}

// TODO: set up removal of this listener
export const setRemovalOfMacroMultiSelectOnClick = () => {
	$('body').on('click', function(e) {
		removeMacroMultiSelect()
	});
}

const commitMacroChanges = (el) => {
	// Set Const
	const initialText = $(el).val()
	const caretStart = el.selectionStart
	const caretEnd = el.selectionEnd
	const newPhrase = getNewPhrase()
	const finalCaretPosition = caretStart + newPhrase.length
	// Change text
	let newText = initialText.substring(0, caretStart)
	newText += newPhrase
	newText += initialText.substring(caretEnd)
	$(el).val(newText)
	el.selectionStart = finalCaretPosition
	el.selectionEnd = finalCaretPosition
	$('#macro-multi-select').remove();
}

const openDynamicMacroMenu = (event) => {
	launchMultiSelect(event)
	setRemovalOfMacroMultiSelectOnClick()
}

const handleDynamicMacro = (event) => {
  const el = event.currentTarget
  const selectionText = getSelectionTextFromElement(el)
  const conjunctionCharacter = setConjunctionCharacter(selectionText)
  dynamicMacroPhraseSelectorRegexGlobal.test(selectionText);
  // if there is conjunctionCharacter open menu, else accept selected text
  if (selectionText.includes(conjunctionCharacter)) {
    openDynamicMacroMenu(event)
  }
} 

const selectedTextIsDynamicMacro = (event) => { 
  const el = event.currentTarget
  const selectionText = getSelectionTextFromElement(el)
  return dynamicMacroPhraseSelectorRegex.test(selectionText);
}