let mediaRecorder;      // For recording the audio
let recordedChunks = [];  // To store the recorded audio data

export function initTranscriberTraining() {

  window.addEventListener('DOMContentLoaded', (function(e) {
    $(document).on('click', '#start-recording-button', function() {
      console.log('start recording')
      startRecording();
    });
    
    $(document).on('click', '#end-recording-button', function() {
      console.log('stop recording')
      stopRecordingAndPrepareForSubmission();
    });
  }), false);

}

// Start recording when user clicks 'Start Recording'
function startRecording() {
  // Ensure getUserMedia API is supported
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        mediaRecorder = new MediaRecorder(stream);

        // Event to gather the recorded chunks
        mediaRecorder.ondataavailable = event => {
          if (event.data.size > 0) {
            recordedChunks.push(event.data);
          }
        };

        // Start recording
        mediaRecorder.start();

        // Hide button
        $('#start-recording-button').hide();
        $('#end-recording-button').show();
      })
      .catch(err => {
        console.error('Error accessing the microphone', err);
      });
  } else {
    alert('Your browser does not support the getUserMedia API.');
  }
}

// Stop recording when user clicks 'Stop Recording'
function stopRecordingAndPrepareForSubmission() {
  mediaRecorder.stop();

  // Create a blob from the recorded audio data
  const audioBlob = new Blob(recordedChunks, { type: 'audio/webm' });

  // You can then append this blob to a FormData object for form submission
  const formData = new FormData(document.getElementById('audio-form'));
  const url = document.getElementById('audio-form').action;
  formData.append('audio_file', audioBlob, 'recordedAudio.webm');  // 'audio' is the field name you'd use server-side

  // Submit the form data (e.g., using fetch API or XMLHttpRequest)
  // This is just an example, adjust the URL and method as needed
  $('#end-recording-button').replaceWith("<span class='disabled button wide-button grey-border'>Submitting - Please Wait</span>");
  fetch(url, {
    method: 'POST',
    body: formData
  })
  .then(response => {
    if (response.redirected) {
      window.location.href = response.url;
    }
  })
  .catch(error => {
    console.error('Error submitting the form:', error);
  });
}
