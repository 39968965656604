import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { category: String, clipboardAttributes: Array }

  initialize() {
    this.handlePatientSelected = this.handlePatientSelected.bind(this);
  }

  connect() {
    this.showFormBasedOnCategory();
    this.highlightButtonBasedOnCategory();
    this.element.addEventListener("patient:selected", this.handlePatientSelected);
  }

  disconnect() {
    this.element.removeEventListener("patient:selected", this.handlePatientSelected);
  }

  showFormBasedOnCategory() {
    this.hideAllForms();

    const formToShowId = `${this.categoryValue}-appointment-form`;
    const formToShow = document.getElementById(formToShowId);
    if (formToShow) {
      formToShow.classList.remove('hidden-field');
    }
  }

  highlightButtonBasedOnCategory() {
    this.removeButtonHighlights();

    const buttonId = `${this.categoryValue}-appointment-button`;
    const buttonToHighlight = document.getElementById(buttonId);
    if (buttonToHighlight) {
      buttonToHighlight.classList.add('green-icon');
    }
  }

  pasteFromClipboard() {
    const clipboardData = this.getClipboardData();
    if (!clipboardData) return;

    // Select the right form template
    this.updateCategory(clipboardData.form_category);

    if (clipboardData.patient_id) {
      // Triggering custom Stimulus action or event
      const event = new CustomEvent("patient:selected", {
        detail: { patientId: clipboardData.patient_id },
        bubbles: true,
        cancelable: true
      });
      this.element.dispatchEvent(event);
    }

    // Assuming you have multiple fields to update
    this.clipboardAttributesValue.forEach(attribute => {
      const inputs = this.element.querySelectorAll(`.clipboard-target-${attribute}`);
      inputs.forEach(input => {
        if (input.type === 'checkbox') {
          // Set the checkbox 'checked' status based on the JSON boolean value
          input.checked = !!clipboardData[attribute];
        } else {
          // Set the value for other types of inputs
          input.value = clipboardData[attribute] || '';
        }
      });
    });
  }

  getClipboardData() {
    const cookie = this.readCookie('appointment_clipboard');
    console.log(decodeURIComponent(cookie));
    return cookie ? JSON.parse(decodeURIComponent(cookie)) : null;
  }

  readCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  removeButtonHighlights() {
    const buttons = document.querySelectorAll("#regular-appointment-button, #blocked-appointment-button, #group-appointment-button");
    buttons.forEach(button => button.classList.remove('green-icon'));
  }

  hideAllForms() {
    const forms = document.querySelectorAll("#regular-appointment-form, #blocked-appointment-form, #group-appointment-form");
    forms.forEach(form => form.classList.add('hidden-field'));
  }

  updateCategory(newCategory) {
    this.categoryValue = newCategory;
    this.showFormBasedOnCategory();
    this.highlightButtonBasedOnCategory();
  }

  toggleRegular() {
    this.updateCategory('regular')
  }

  toggleBlocked() {
    this.updateCategory('blocked')
  }

  toggleGroup() {
    this.updateCategory('group')
  }

  handlePatientSelected(event) {
    // event.detail contains the details of the selected patient
    const { patientId, patientName } = event.detail;

    // Call showDemographics or any other function that should react to this event
    this.showDemographics(patientId);
  }

  showDemographics(patientId) {
    const url = `/schedule_view/patients/${patientId}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html));
  }

}
