import { consumer, isSubscribed } from "../consumer"

window.subscribeToTranscription = function(transcriptionId) {
  subscribeToSubscription(transcriptionId);
}

const subscribeToSubscription = function(transcriptionId) {
  // In some cases the JS can have a blank transcriptionId and throw an error on the
  // subscribe to channel list
  if (!transcriptionId) {
    return true
  }
  let subscriptionObject = {
    channel: 'EmrChannels::TranscriptionChannel',
    id: transcriptionId
  }
  let content = document.querySelector('#transcription-audio-files-index')
  console.log('subscribed to transcription')
  if (isSubscribed(subscriptionObject)) {
    return true
  }
  consumer.subscriptions.create(
    subscriptionObject,
    {
      initialized() {

      },
      received(data) {
        switch(data['messageType']) {
          case 'transcription_audio_file_status_update':
            transcriptionAudioFileStatusUpdate(data['id'], data['status'], data['message']);
            break;
          case 'transcription_data_update':
            transcriptionDataUpdate(data['message']);
            transcriptionStatusUpdate(data['status']);
            break;
          case 'transcription_audio_file_oracle':
            transcriptionAudioFileOracle(data['id'], data['medical_concepts']);
            break;
        }
      },

      // Call when subscription is ready for use on the server
      connected() {

      },
    }
  );
};

const unsubscribeFromThread = function(transcriptionId) {
  let channelId = `{\"channel\":\"EmrChannels::InstantMessengerThreadChannel\",\"thread_id\":${transcriptionId}}`
  let subscriptions = consumer.subscriptions.findAll(channelId)
  subscriptions.forEach(subscription => consumer.subscriptions.remove(subscription))
};

function transcriptionAudioFileStatusUpdate(id, status, message) {
  let transcriptionAudioFileContainer = $(`[data-transcription-audio-file-id="${id}"]`)[0];

  switch(status) {
    case 'Completed':
      transcriptionAudioFileContainer.classList.add('status-completed');
      transcriptionAudioFileContainer.classList.remove('status-error');
      transcriptionAudioFileContainer.classList.remove('status-in-progress');
      transcriptionAudioFileContainer.classList.remove('status-pending');
      break;
    case 'Error':
      transcriptionAudioFileContainer.classList.add('status-error');
      transcriptionAudioFileContainer.classList.remove('status-completed');
      transcriptionAudioFileContainer.classList.remove('status-in-progress');
      transcriptionAudioFileContainer.classList.remove('status-pending');
      break;
    case 'In Progress':
      transcriptionAudioFileContainer.classList.add('status-in-progress');
      transcriptionAudioFileContainer.classList.remove('status-error');
      transcriptionAudioFileContainer.classList.remove('status-completed');
      transcriptionAudioFileContainer.classList.remove('status-pending');
      break;
    case 'Pending':
      transcriptionAudioFileContainer.classList.add('status-pending');
      transcriptionAudioFileContainer.classList.remove('status-error');
      transcriptionAudioFileContainer.classList.remove('status-in-progress');
      transcriptionAudioFileContainer.classList.remove('status-completed');
      break;
  }
}

function transcriptionDataUpdate(message) {
  let transcriptionDataContainer = document.querySelector('#transcription-data');
  transcriptionDataContainer.innerHTML = message;
}

function transcriptionStatusUpdate(status) {
  let transcriptionDataContainer = document.querySelector('#scribe-status-container');
  if (status == 'Completed') {
    $(transcriptionDataContainer).hide()
  }
}

function transcriptionAudioFileOracle(id, medical_concepts) {
  let oracleContainer = $('#oracle-index');

  // Create a new div for medical concepts
  let medicalConceptsDiv = document.createElement('div');
  medicalConceptsDiv.classList.add('ava-flex', 'medical_concepts', 'text-overflow-ellipsis');

  // Loop through the medical concepts and add spans with links
  medical_concepts.slice(0, 4).forEach(concept => {
    let span = document.createElement('span');
    let link = document.createElement('a');
    link.href = `/patient_view/patients/${concept.patient_id}/medical_concepts/${concept.id}?transcription_audio_file_id=${id}`;
    link.textContent = concept.name;
    span.classList.add('ava-col-3');
    link.classList.add('button', 'text-overflow-ellipsis', 'grey-border', 'wide-button');
    link.setAttribute('data-remote', 'true');
    link.title = concept.name;
    span.appendChild(link);
    medicalConceptsDiv.appendChild(span);
  });

  // Prepend the new div to the oracle container
  oracleContainer.prepend(medicalConceptsDiv);

  // Check and remove the last div if more than 2 exist
  let allMedicalConceptsDivs = oracleContainer[0].querySelectorAll('.medical_concepts');
  if (allMedicalConceptsDivs.length > 2) {
    allMedicalConceptsDivs[allMedicalConceptsDivs.length - 1].remove();
  }
}
