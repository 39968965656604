window.initializeTranscriberRecorder = initializeTranscriberRecorder;

function initializeTranscriberRecorder() {
  const startButton = document.querySelector('#start-recording');
  const stopButton = document.querySelector('#stop-recording');
  const transcriptionSgid = document.querySelector('#transcription-sgid').getAttribute('data-transcription-sgid');
  const finalizeButton = document.querySelector('#finalize-transcription-button');
  const footerButton = document.querySelector('#toggle-transcription-button');
  const deleteButton = document.querySelector('#delete-transcription-button');
  const micFeedback = document.querySelector('#mic-feedback'); // Element to show mic feedback
  const micInfo = document.querySelector('#mic-info'); // Element to show mic info

  let mediaRecorder = null;
  let stream = null;
  let chunks = [];
  let timer = new Timer();
  let startTime = 0;
  let stopTime = 0;
  let lastGreenUpdateTime = 0; // To track the last time the feedback was green

  window.transcriberTimer = timer;

  const startRecording = async () => {
    try {
      window.onbeforeunload = function() {
        return 'There is an Ava Scribe in progress - if you leave it will be lost. Are you sure you want to leave?';
      };

      timer.start();
      startButton.classList.add('hidden-field');
      footerButton.classList.add('instant-messenger-unread');
      stopButton.classList.remove('hidden-field');

      if (window.scribeRecording) {
        return;
      };

      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder = new MediaRecorder(stream);

      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();
      analyser.smoothingTimeConstant = 0.9;
      analyser.fftSize = 512;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      source.connect(analyser);

      function analyzeMicLevel() {
        analyser.getByteFrequencyData(dataArray);
        const sum = dataArray.reduce((a, b) => a + b, 0);
        const average = sum / dataArray.length;

        const now = Date.now();

        if (micFeedback) {
          if (average > 55) {
            micFeedback.style.color = 'green';
            lastGreenUpdateTime = now;
          } else if (average > 30) {
            if (now - lastGreenUpdateTime > 1500) {
              micFeedback.style.color = 'orange';
            }
          } else {
            if (now - lastGreenUpdateTime > 1500) {
              micFeedback.style.color = 'red';
            }
          }
        }

        requestAnimationFrame(analyzeMicLevel);
      }

      analyzeMicLevel();

      // Display the name of the microphone being used
      const audioTracks = stream.getAudioTracks();
      if (audioTracks.length > 0 && micInfo) {
        micInfo.textContent = `${audioTracks[0].label}`;
      }

      mediaRecorder.addEventListener('dataavailable', (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data);
        }
      });

      mediaRecorder.addEventListener('stop', async () => {
        stopTime = Date.now();
        const blob = new Blob(chunks, { type: 'audio/webm' });
        saveAudio(blob);
        chunks = [];
      });

      mediaRecorder.addEventListener('start', async () => {
        startTime = Date.now();
        chunks = [];
      });

      mediaRecorder.start();

      setInterval(async () => {
        if (mediaRecorder && mediaRecorder.state === 'recording') {
          mediaRecorder.stop();
          mediaRecorder.start();
        }
      }, 0.5 * 60 * 1000); // 30 sec

      window.scribeRecording = true;
    } catch (err) {
      console.error('Error while starting recording:', err);
      alert('Unable to access the microphone. Please check your microphone settings and permissions.');
      startButton.classList.remove('hidden-field');
      footerButton.classList.remove('instant-messenger-unread');
      stopButton.classList.add('hidden-field');
      window.onbeforeunload = null;
      timer.stop();
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
      startButton.classList.remove('hidden-field');
      footerButton.classList.remove('instant-messenger-unread');
      stopButton.classList.add('hidden-field');
      window.onbeforeunload = null;
      timer.stop();
      stream.getTracks().forEach(track => track.stop());
      window.scribeRecording = null;
    }
  };

  const saveAudio = async (blob) => {
    const formData = new FormData();
    let duration = Math.round((stopTime - startTime) / 100) / 10;
    formData.append('audio_file', blob);
    formData.append('transcription_sgid', transcriptionSgid);
    formData.append('duration', duration);
    formData.append('order', Date.now());
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    try {
      const response = await fetch('/transcriber/transcription_audio_files', {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': csrfToken,
        },
        credentials: 'same-origin',
      });

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
        window.onbeforeunload = null;
      }

      const data = await response.json();
      addTranscriptionAudioFileElement(data, duration);

    } catch (err) {
      window.onbeforeunload = null;
    }
  };

  const deleteTranscription = async () => {
    const userConfirmed = confirm("If deleted, you cannot recover this Scribe. Are you sure you want to delete this Scribe?");

    if (!userConfirmed) {
      return;
    }
    stopRecording();
    window.scribeRecording = null;

    const url = deleteButton.getAttribute('data-url');
    const csrfToken = $('meta[name="csrf-token"]').attr('content');

    $.ajax({
      url: url,
      method: 'DELETE',
      dataType: 'script',
      headers: {
        'X-CSRF-Token': csrfToken
      },
      success: function(response) {
        timer.stop();
        window.scribeRecording = null;
      },
    });
  };

  const finalizeTranscription = async () => {
    const url = finalizeButton.getAttribute('data-url');
    const csrfToken = $('meta[name="csrf-token"]').attr('content');
    finalizeButton.disabled = true;
    finalizeButton.classList.add('disabled');

    if (window.scribeRecording) {
      stopRecording();
      setTimeout(() => {
        patchFinalizeTranscription(url, csrfToken);
      }, 1000);
    } else {
      patchFinalizeTranscription(url, csrfToken);
    }
  };

  startButton.addEventListener('click', startRecording);
  stopButton.addEventListener('click', stopRecording);
  deleteButton.addEventListener('click', deleteTranscription);
  finalizeButton.addEventListener('click', finalizeTranscription);
}

function patchFinalizeTranscription(url, csrfToken) {
  $.ajax({
    url: url,
    method: 'PATCH',
    dataType: 'script',
    headers: {
      'X-CSRF-Token': csrfToken
    }
  });
}

function addTranscriptionAudioFileElement(data, duration) {
  const id = data.id;

  const newSpan = document.createElement('span');
  newSpan.classList.add('transcriber-audio-file');
  newSpan.setAttribute('data-transcription-audio-file-id', id);
  $('#transcription-audio-files-index').append(newSpan);
}

class Timer {
  constructor() {
    this.startTime = 0;
    this.elapsedTime = 0;
    this.timerInterval = null;
    this.seconds = 0;
    this.minutes = 0;
    this.hours = 0;
  }

  start() {
    if (!this.timerInterval) {
      this.startTime = Date.now() - this.elapsedTime;
      this.timerInterval = setInterval(() => {
        this.elapsedTime = Date.now() - this.startTime;
        this.update();
      }, 1000);
    }
  }

  stop() {
    clearInterval(this.timerInterval);
    this.timerInterval = null;
  }

  update() {
    const startTime = parseInt($('#transcriber-timer').data('start-time'));
    const diffInSeconds = (Math.floor(this.elapsedTime / 1000) + startTime);
    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    const displayHours = String(hours).padStart(2, '0');
    const displayMinutes = String(minutes).padStart(2, '0');
    const displaySeconds = String(seconds).padStart(2, '0');

    let element = document.querySelector('#transcriber-timer');
    element.textContent = `${displayHours}:${displayMinutes}:${displaySeconds}`;
  }
}
