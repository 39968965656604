/**
Add a Stimulus controller for this component.
It will automatically registered and its name will be available
via component-name

You have to export export class Controller extends BaseController 
to use index.js as a stimulus controller. If you need to use a 
stimulus controller by naming the conventional way 
(i.e. the file ends in _controller.js) you need to export default 
like the following so that the controller is properly registered:

import { Controller } from "@hotwired/stimulus"; 

export default class extends Controller { 
  ... 
} 
 */

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static values = {
    content: String,
    position: { type: String, default: 'right' }
  }

  connect() {
    this.tooltip = null
    this.showTooltipBound = this.showTooltip.bind(this)
    this.hideTooltipBound = this.hideTooltip.bind(this)

    this.element.addEventListener('mouseenter', this.showTooltipBound)
    this.element.addEventListener('mouseleave', this.hideTooltipBound)
  }

  disconnect() {
    this.element.removeEventListener('mouseenter', this.showTooltipBound)
    this.element.removeEventListener('mouseleave', this.hideTooltipBound)
    this.hideTooltip()
  }

  showTooltip() {
    if (this.tooltip) return

    this.tooltip = document.createElement('div')
    this.tooltip.classList.add('ava-tooltip')
    this.tooltip.innerHTML = this.contentValue
    document.body.appendChild(this.tooltip)

    this.positionTooltip()
  }

  positionTooltip() {
    const rect = this.element.getBoundingClientRect()
    const tooltipRect = this.tooltip.getBoundingClientRect()

    let top, left
    const margin = 10 // Space between tooltip and element

    switch (this.positionValue) {
      case 'bottom':
        top = rect.bottom + window.scrollY + margin
        left = rect.left + window.scrollX + (rect.width / 2) - (tooltipRect.width / 2)
        break
      case 'left':
        top = rect.top + window.scrollY + (rect.height / 2) - (tooltipRect.height / 2)
        left = rect.left + window.scrollX - tooltipRect.width - margin
        break
      case 'right':
        top = rect.top + window.scrollY + (rect.height / 2) - (tooltipRect.height / 2)
        left = rect.right + window.scrollX + margin
        break
      default: // top
        top = rect.top + window.scrollY - tooltipRect.height - margin
        left = rect.left + window.scrollX + (rect.width / 2) - (tooltipRect.width / 2)
    }

    // Adjust for viewport overflow
    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight

    // Adjust horizontal position
    if (left < window.scrollX) {
      left = window.scrollX + margin
    } else if (left + tooltipRect.width > window.scrollX + viewportWidth) {
      left = window.scrollX + viewportWidth - tooltipRect.width - margin
    }

    // Adjust vertical position
    if (top < window.scrollY) {
      top = window.scrollY + margin
    } else if (top + tooltipRect.height > window.scrollY + viewportHeight) {
      top = window.scrollY + viewportHeight - tooltipRect.height - margin
    }

    this.tooltip.style.top = `${top}px`
    this.tooltip.style.left = `${left}px`

    // Update tooltip arrow position
    this.updateArrowPosition(rect, { top, left, width: tooltipRect.width, height: tooltipRect.height })
  }

  updateArrowPosition(elementRect, tooltipRect) {
    const arrow = document.createElement('div')
    arrow.classList.add('ava-tooltip-arrow')
    this.tooltip.appendChild(arrow)

    let arrowStyle = ''
    switch (this.positionValue) {
      case 'bottom':
        arrowStyle = `
          top: -10px;
          left: ${elementRect.left + elementRect.width / 2 - tooltipRect.left - 13}px;
          border-bottom: 10px solid black;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
        `
        break
      case 'left':
        arrowStyle = `
          right: -10px;
          top: ${elementRect.top + elementRect.height / 2 - tooltipRect.top + window.scrollY - 13}px;
          border-left: 10px solid black;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
        `
        break
      case 'right':
        arrowStyle = `
          left: -10px;
          top: ${elementRect.top + elementRect.height / 2 - tooltipRect.top + window.scrollY - 13}px;
          border-right: 10px solid black;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
        `
        break
      default: // top
        arrowStyle = `
          bottom: -10px;
          left: ${elementRect.left + elementRect.width / 2 - tooltipRect.left - 13}px;
          border-top: 10px solid black;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
        `
    }

    arrow.style.cssText = `
      position: absolute;
      width: 0;
      height: 0;
      ${arrowStyle}
    `
  }

  hideTooltip() {
    if (this.tooltip) {
      this.tooltip.remove()
      this.tooltip = null
    }
  }
}
